import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import DPlayer from "dplayer";
export default {
  name: "card-list",
  data() {
    return {
      dp: null,
      videoVisible: false,
      picTitle: "",
      videoTitle: "",
      file: {},
      data: [{
        url: "https://gw.alipayobjects.com/zos/rmsportal/iXjVmWVHbCJAyqvDxdtx.png",
        name: "XBoot",
        type: "image",
        fkey: "XBoot 是很不错的Web前后端分离架构开发平台",
        size: 28659,
        createTime: "2018-10-22"
      }, {
        url: "https://gw.alipayobjects.com/zos/rmsportal/iZBVOIhGJiAnhplqjvZW.png",
        name: "Exrick",
        type: "image",
        fkey: "XBoot 是很不错的Web前后端分离架构开发平台",
        size: 18659,
        createTime: "2018-10-22"
      }, {
        url: "https://cloud.video.taobao.com//play/u/95292294/p/1/e/6/t/1/210877258798.mp4",
        name: "视频",
        type: "video",
        fkey: "XBoot 是很不错的Web前后端分离架构开发平台",
        size: 8659,
        createTime: "2018-10-22"
      }, {
        url: "https://icarusion.gitee.io/iview/e1cf12c07bf6458992569e67927d767e.png",
        name: "XBoot",
        type: "txt",
        fkey: "XBoot 是很不错的Web前后端分离架构开发平台",
        size: 8659,
        createTime: "2018-10-22"
      }],
      data2: [{
        url: "https://gw.alipayobjects.com/zos/rmsportal/iXjVmWVHbCJAyqvDxdtx.png",
        title: "XBoot",
        description: "XBoot 是很不错的Web前后端分离架构开发平台",
        author: [{
          name: "XBoot",
          avatar: "https://s1.ax1x.com/2018/05/19/CcdVQP.png"
        }, {
          name: "Exrick",
          avatar: "https://ooo.0o0.ooo/2021/01/16/VuODA1yUSCeXzFM.png"
        }],
        createTime: "2018-10-22"
      }, {
        url: "https://gw.alipayobjects.com/zos/rmsportal/iZBVOIhGJiAnhplqjvZW.png",
        title: "Exrick",
        description: "XBoot 是很不错的Web前后端分离架构开发平台",
        author: [{
          name: "XBoot",
          avatar: "https://s1.ax1x.com/2018/05/19/CcdVQP.png"
        }, {
          name: "Exrick",
          avatar: "https://ooo.0o0.ooo/2021/01/16/VuODA1yUSCeXzFM.png"
        }],
        createTime: "2018-10-22"
      }]
    };
  },
  methods: {
    init() {},
    showPic(v, i) {
      let viewer = new Viewer(document.getElementById("image"), {
        hidden: function () {
          viewer.destroy();
        }
      });
      viewer.view(i);
    },
    showVideo(v) {
      this.dp = new DPlayer({
        container: document.getElementById("dplayer-card"),
        screenshot: true,
        video: {
          url: v.url
        }
      });
      this.file = v;
      this.file.msize = this.util.getFileSize(v.size);
      this.videoTitle = v.name + "(" + v.fkey + ")";
      this.videoVisible = true;
    },
    closeVideo() {
      if (this.dp) {
        this.dp.destroy();
      }
    },
    download(v) {
      window.open(v.url + "?attname=&response-content-type=application/octet-stream");
    },
    copy(v) {
      this.$Message.info("点击了 " + v.name + " 的复制图标");
    },
    remove(v) {
      this.$Message.info("点击了 " + v.name + " 的删除图标");
    },
    rename(v) {
      this.$Message.info("点击了 " + v.name + " 的重命名图标");
    }
  },
  mounted() {
    this.init();
  }
};